import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";

import { Nav, Navbar, Button, Dropdown, DropdownButton} from "react-bootstrap";

import aetnalogo from "../assets/images/aetna_logo.jpg"

import cofinitylogo from "../assets/images/Cofinity_Logo.png"
import fcomlogo from "../assets/images/FCOM_Logo.png"
import fhlogo from "../assets/images/FH_Logo.png"

import { loginRequest, b2cPolicies } from "../authConfig";

import "../styles/index.css"

import envConfig from "../config";


export const NavigationBar = () => {

    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginPopup(loginRequest)
            .catch((error) => console.log(error))
    }

    // const handleLogin = () => {
    //     instance.loginPopup(loginRequest).catch((error) => {
    //         if (error.errorMessage && error.errorMessage.indexOf("AADB2C90118") > -1) {
    //             instance.loginPopup(forgotPasswordRequest)
    //                 .then(() => {
    //                     window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
    //                 });
    //         }
    //     });
    //}

    /**
     * Most applications will need to conditionally render certain components based on whether a user is signed in or not. 
     * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will 
     * only render their children if a user is authenticated or unauthenticated, respectively.
     */
    return (
        <>
        {/* <Navbar bg="primary" variant="dark" style={{backgroundColor: "white"}}> */}
        <Navbar style={{backgroundColor:"white"}} variant="dark">
            {envConfig.appName === 'cfsi'? 
            <>
            <img src={aetnalogo} alt="Logo" width="150" />
            <a className="navbar-brand" href="/"></a> 
                    <h2 className='fmemail'>MRF Transparency Portal</h2> </>
                    :""}        
                     
            {envConfig.appName === 'cffh'? 
            <>
            <img src={fhlogo} alt="Logo" width="150" /> &nbsp;&nbsp;&nbsp;  
            <img src={fcomlogo} alt="Logo" width="80" /> &nbsp;&nbsp;&nbsp; 
            <img src={cofinitylogo} alt="Logo" width="80" /> 
            <a className="navbar-brand" href="/"></a> 
                    <h2 className='fmemail'>First Health Transparency Portal</h2> </>
                    :""}  
              
            <AuthenticatedTemplate>
                <div className="ml-auto">
                    {/* <Button variant="info" onClick={() => instance.loginPopup(b2cPolicies.authorities.editProfile)} className="ml-auto mr-2">Edit Profile</Button> */}
                    <Button variant="info" onClick={() => instance.logoutPopup({ postLogoutRedirectUri: "/", mainWindowRedirectUri: "/" })} className="ml-auto">Log out</Button>
                </div>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Button variant="info" onClick={handleLogin} className="ml-auto">Sign in</Button>
            </UnauthenticatedTemplate>
        </Navbar>
    </>
    );
};