import React from 'react';
//import filelistMockData from '../mockdata.json';
//import { Button } from "react-bootstrap";
//import * as ReactBootStrap from "react-bootstrap";
import "../styles/App.css";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import { grey, blueGrey, pink, purple } from '@mui/material/colors';
//import multiDownload from 'multi-download';
import $ from 'jquery';
import TextField from '@mui/material/TextField';
import MUIDataTable from "mui-datatables";



// const Table = ({ headers, data }) => {
//   return (
//     <div>
//       <table>
//         <thead>
//           <tr>
//             {headers.map(head => (
//               <th>{head}</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {data.map(row => (
//             <tr>
//               {headers.map(head => (
//                 <td>{row[head]}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: purple['300'],
        color: theme.palette.common.black,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


const handleRowClick = (rowData, rowMeta) => {
    console.log(rowData);
    console.log(rowMeta);
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:nth-of-type(even)': {
        backgroundColor: purple[50],
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

class FileTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bgColor: "",
            page: 0,
            rowsPerPage: 10,
            showDialog: false,
            searchedVal: "",
            data: [],
            selectData: [],
            selectRowData: [],
            selectableRows: "multiple",
            searchList: [],
            downloadselection: "none",

        };

        //this.downloadAll = this.downloadAll.bind(this);
        this.download = this.download.bind(this);
        this.downloadAllI = this.downloadAllI.bind(this);
        this.saveRowSelectData = this.saveRowSelectData.bind(this);
        this.downloadPartial = this.downloadPartial.bind(this);
        //this.buttonSelection = this.buttonSelection.bind(this);

        this.setState({
            data: this.props.list,
            searchList: []
        })

    }

    //    componentDidMount() {
    //      //   this.setState({
    //        //     data: this.props.list
    //         //})

    //         //console.log(this.state.data);

    //     }


    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage
        })
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            page: 0,
            rowsPerPage: +event.target.value
        })
    };

    saveRowSelectData(rowData) {

        //this.setState(prevState=>({selectData:[rowData,...prevState.selectData]}))
        this.state.selectData.push(rowData)
        console.log(this.selectData)

    }

    downloadFile(row, index) {
        console.log("download");
        this.props.onclick(row);
        const seqid = row.SequenceNo;
        console.log("seqid " + seqid);
        console.log("index " + index);
        console.log("row" + row._id$oid);
        document.getElementById(row.SequenceNo).style.backgroundColor = "red";
    }

    downloadAllJ(list) {

        var link = document.createElement('a');
        link.style.display = 'none';
        link.setAttribute('download', 'download');

        var obj = list;

        obj.forEach(myFunction);

        function myFunction(item, index) {
            $.ajax({
                "async": false,
                "crossDomain": true,
                "url": item.URL,
                "method": "GET",
                dataType: 'text',
            }).done(function (data) {
                console.log(item);
                var blob = new Blob([data], { type: "application/text" });

                //Check the Browser type and download the File.
                var isIE = false || !!document.documentMode;
                if (isIE) {
                    window.navigator.msSaveBlob(blob, fileName);
                } else {
                    var url = window.URL || window.webkitURL;
                    link = url.createObjectURL(blob);
                    var a = $("<a />");
                    a.attr("download", item.FileName);
                    a.attr("href", link);
                    $("body").append(a);
                    a[0].click();
                    $("body").remove(a);
                }
            }).fail(function (jqXHR, textStatus, errorThrown) {
                console.log("Request failed: " + textStatus + " , " + errorThrown);
            });
        }
    }


    downloadAllI(list) {
        for (let i = 0; i < list.length; i++) {
            var frame = document.createElement("iframe");
            frame.src = list[i].URL;
            frame.style = "display:none;"
            console.log(list[i].SequenceNo);
            console.log("url" + list[i].URL);
            frame["download"] = 1
            document.body.appendChild(frame);

            // const data =  list[i].URL;
            // const url = URL.createObjectURL(new Blob(data));
            // const link = document.createElement('a');
            // link.href = url;
            // link.click();


        }
        //document.body.removeChild(frame);

    }


    downloadPartial(selectRowData) {

        console.log("searchList ", selectRowData)
        console.log("searchList Length", selectRowData.length)

        if (selectRowData.length===0){
            alert( "Please select the file name to download")
        }    

        for (let i = 0; i < selectRowData.length; i++) {
            var frame = document.createElement("iframe");
            frame.src = selectRowData[i];
            frame.style = "display:none;"
            console.log("url" + selectRowData[i])
            frame["download"] = 1
            document.body.appendChild(frame);

            // const data = selectRowData[0];
            // const url = URL.createObjectURL(new Blob(data));
            // const link = document.createElement('a');
            // link.href = url;
            // link.click();

        }
        //document.body.removeChild(frame);

    }

    download(searchList) {

        // console.log("searchList Length",searchList[1] )

        var searchValue = document.querySelectorAll("input")[0].value;
        var getFileName;
        var getDate;
        console.log("searchValue", searchValue)
        var apiList = this.props.list
        getFileName = apiList.filter(x => x.FileName.includes(searchValue))
        getDate = apiList.filter(y => new Date(y.CreationTime).toLocaleString().includes(searchValue))

        for (let i = 0; i <= getFileName.length; i++) {
            console.log("searchBy Date", apiList)
            console.log("getFileName", getFileName);
            console.log("getFileName", getFileName[i].URL);


            if (getFileName.length > 0) {
                console.log("serarchDownload")
                var frame = document.createElement("iframe");
                frame.src = getFileName[i].URL;
                frame.style = "display:none;"
                //console.log("url" + getFileName[i].URL)
                frame["download"] = 1
                document.body.appendChild(frame);

            } else if (getDate.length > 0) {
                var frame = document.createElement("iframe");
                frame.src = getDate[i].URL;
                frame.style = "display:none;"
                frame["download"] = 1
                document.body.appendChild(frame);

            } else {
                console.log("No data found")

            }



        }

        getFileName = [];
        getDate = [];



        // for (let i =0; i< searchList.length/2; i++){

        //     console.log("searchList", searchList[i])
        //     var frame = document.createElement("iframe");
        //     frame.src = searchList[i];
        //     frame.style = "display:none;"
        //     console.log("url" + searchList[i])
        //     frame["download"] = 1
        //     document.body.appendChild(frame);
        //     //this.setState({data: index[selectData[i]]})
        // }

        // // for (let i = 0; i <11; i++) {

        // //     const link = document.createElement('a');
        // //     link.href = searchList[i];
        // //     document.body.appendChild(link);
        // //     link.click();
        // //     //document.body.removeChild(link);
        // // }

        // while(searchList.length>0){
        //     searchList.pop(); 
        // }
    }

    

    buttonSelection(){
    if (this.state.selectRowData.length=0){
        console.log("block");
        this.setState({downloadselection:"none",})
    }else{
        console.log("none");
        this.setState({downloadselection:"block",})

    }
}

    render() {
        const list = this.props.list;
        const listSize = list.length;
        let page = this.state.page;
        console.log("Page count", page);
        let rowsPerPage = this.state.rowsPerPage;
        console.log("Rows perpage", rowsPerPage);
        var searchList = [];
        var selectRowData = [];

        // const data = [[this.props.list[0].FileName]]
        let rows = this.props.list;

        if (page > listSize / rowsPerPage) {
            page = 0

        }

        const columns = [
            // {
            //     name: "SequenceNo",
            //     options: {
            //       filter: false
            //     }
            //   },
            {
                name: "FileName",
                label: "File Name",
                options: {
                    filter: true
                }
            },
            {
                name: "Size",
                label: "Size (MB)",
                options: {
                    filter: false,
                    customBodyRender: value => parseFloat(value).toFixed(6),
                }
            },
            {
                name: "CreationTime",
                label: "Creation Time",
                options: {
                    filter: true,
                    customBodyRender: value => new Date(value).toLocaleString(),
                }
            },
            //   {
            //     name: "Download",
            //         label: "Actions",
            //         options: {
            //             customBodyRender: (value, tableMeta, updateValue) => {
            //                 return (
            //                     <button onClick={() => console.log(value, tableMeta) }>
            //                         Download
            //                     </button>
            //                 )
            //             }
            //         }
            //     },
            {
                name: "URL",
                label: "Direct Storage URL",
                options: {
                    filter: false,
                    customBodyRender: (value, tableMeta, updateValue) => {
                        return (
                            <a href={value} rel="noopener noreferrer" target="_blank">Download</a>

                        );
                    }
                }
            },
        ];


        const options = {
            filterType: 'checkbox',
            //selectableRows: 'none',
            selectableRows: this.state.selectableRows,
            selectableRowsHeader: true,
            selectableRowsHideCheckboxes: false,
            //selectableRowsHeader: data.length > 0,
            //rowsSelected: this.state.rowsSelected,
            //selectableRowsOnClick: true,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 25, 50, 100],
            jumpToPage: true,
            filter: false,
            download: false,
            print: false,
            viewColumns: false,
            //responsive: 'scrollMaxHeight',
            onRowsDelete: false,
            delete: false,
            searchPlaceholder: 'Search by File Name or Creation Date Time',
            fixedHeader: true,
            fixedSelectColumn: false,
            tableBodyHeight: '600px',
            selectToolbarPlacement: "none",  ///delete icon and display selected rows count text
            //selectableRowsHeader: false,  // Header checkbox
            //resizableColumns: true,
            // searchOpen: true,
            //responsive: "scroll",
            //customToolbarSelect: () => {},

            displaySelectToolbar: false,

            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => (
                <div className="mailIconDiv">
                    <IconButton
                        color="primary"
                        onClick={() => this.handleClick()}
                    >
                        <i className="zmdi zmdi-email"></i>
                    </IconButton>
                </div>
            ),

            onSearchClose: () => {
                // logic
                this.setState({ selectableRows: 'multiple' })
                document.getElementById("downloadPartial").style.display = 'block'
                document.getElementById("download").style.display = 'none'


            },

            onSearchOpen: () => {
                // logic
                this.setState({ selectableRows: 'none' })
                document.getElementById("downloadPartial").style.display = 'none'
                document.getElementById("download").style.display = 'block'


            },

            onRowSelectionChange: (currentSelect, allSelected, rowsSelectedData, allRows, rowsSelected, currentTableData) => {
                console.log(rowsSelectedData);
                console.log(rowsSelected);
                console.log(allRows);
                console.log(allSelected);
                console.log(currentTableData);
                const result = allSelected.map(item => { return this.props.list.at(item.index) });

                console.log(result);
                const selectedIds = result.map(item => {
                    return item.URL;
                });
                //console.log(selectedIds);
                this.setState({
                    selectRowData: selectedIds,
                })
                //selectRowData.push(selectedIds);  
                console.log(this.state.selectRowData);
                //this.buttonSelection();

            }


        };

        return (
            <div>
                {

                    !rows.length > 0 ? <div>No Files Found </div> :
                        <TableContainer>

                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                <Button variant="contained" onClick={() => { this.downloadPartial(this.state.selectRowData) }} id={"downloadPartial"}> Download Selection</Button>  &nbsp;

                                <Button variant="contained" style={{ float: 'right' }} color="primary" className="float-right" onClick={() => { this.download(searchList) }} id={"download"}> Download Search Results</Button>&nbsp;
                                {/* <Button variant="contained" onClick={() => { this.downloadAllI(this.props.list) }} id={"downloadAllI"}> Download All</Button> */}
                            </div>
                            <div className='dtext'>
                            <br></br>
                            <p style={{ color: 'red' }}> 
                            NOTE - Please be aware that due to size of these files, attempting to download multiple files simultaneously through the browser may result in unsuccessful downloads. We strongly recommend limiting your simultaneous downloads to no more than 10 files at a time. This approach increases the likelihood of successful download of the files. 
                            </p>    
                            </div>
                            {/* </div> */}

                            {/* <Button
          style={{
            right: '17rem',
            position: 'absolute',
            top: '1.5rem',
            background: 'gray',
            color: 'white',
            zIndex: 10,
          }}
          onClick={() => this.setState(prevState => ({ downloadFile: !prevState.downloadFile }))}>
          {this.state.downloadFile ? 'Disable' : 'Enable'} Search Download
        </Button> */}

                            <MUIDataTable
                                data={this.props.list}
                                columns={columns}
                                options={options}
                            />

                        </TableContainer>



                }
            </div>

        )
    }

}

export default FileTable;
