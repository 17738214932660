import { useSlotProps } from '@mui/base';
import { Button, Table , Input, Space ,Tooltip} from 'antd';
import { useState,useRef} from 'react';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
import "../styles/App.css";


const FileAPIData = (props) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [tableKey, setTableKey] = useState(0);

  const text = <span> Search</span>;

  const resetFilters = () => {
    setTableKey(tableKey => tableKey + 1);
    setSelectedRowKeys([]);
      setSearchText("");
      setSearchedColumn(0);
      //window.location.reload();
      setLoading(false);
};

  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setSearchText("");
      setSearchedColumn(0);
      //window.location.reload();
      setTableKey(tableKey => tableKey + 1);
      setLoading(false);
    }, 1000);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
};
const handleReset = (clearFilters) => {
    clearFilters();
    setSearchedColumn('FileName');
};

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
        <div
            style={{
                padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{
                    marginBottom: 8,
                    display: 'block',
                }}
            />
            <Space>
              <Tooltip placement="topLeft" title={text}>
                <Button
                    type="primary"
                    onClick={() => {
                        handleSearch(selectedKeys, confirm, dataIndex)
                        console.log("selectedKeys", selectedKeys);
                        console.log("dataIndex", dataIndex);
                    }}
                    icon={<SearchOutlined/>}
                    size="small"
                    style={{
                        width: 90,
                    }}
                >
                    Search
                </Button>
                </Tooltip>
                <Button
                    onClick={() => {
                        handleReset(clearFilters)
                        setSearchText(selectedKeys[0])
                        setSearchedColumn(dataIndex);
                        console.log("selectedKeys", selectedKeys);
                        console.log("dataIndex", dataIndex);
                        console.log("confirm", confirm);
                        handleSearch('', confirm, dataIndex);
                    }}
                    size="small"

                    style={{
                        width: 90,
                    }}
                >
                    Clear
                </Button>
                {/* <Button
        type="link"
        size="small"
        onClick={() => {
          confirm({
            closeDropdown: false,
          });
          setSearchText(selectedKeys[0]);
          setSearchedColumn(dataIndex);
        }}
      >
        Filter
      </Button> */}
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        close();
                    }}
                >
                    close
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined
            style={{
                color: filtered ? '#0079FF' : undefined, fontSize:"xx-large"
            }}
        />
    ),
    onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
    },

    
    render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{
                    backgroundColor: '#ffc069',
                    padding: 0,
                }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
});


  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const downloadFile=()=>{

    for (let i = 0; i < selectedRowKeys.length; i++) {
        var frame = document.createElement("iframe");
        frame.src = selectedRowKeys[i];
        frame.style = "display:none;"
        console.log(selectedRowKeys[i]);
        //console.log("url" + list[i].URL);
        frame["download"] = 1
        document.body.appendChild(frame);

    }

  };

  const columns = [
    {
        title: 'File Name',
        dataIndex: 'FileName',
        key: 'FileName',
        sorter: (a, b) => a.FileName > b.FileName,
        //defaultSortOrder: 'descend',
        ...getColumnSearchProps('FileName'),

    },
    {
        title: 'Size (MB)',
        dataIndex: 'Size',
        key: 'Size',
        //   filters: [
        //     {
        //       text: 'Male',
        //       value: 'male',
        //     },
        //     {
        //       text: 'Female',
        //       value: 'female',
        //     },
        //   ],
        sorter: (a, b) => a.Size > b.Size,
        width: 80,
    },
    {
        title: 'Creation Time',
        dataIndex: 'CreationTime',
        key: 'CreationTime',
        render: (CreationTime) => { return (<p>{moment(CreationTime).format("MM/DD/YYYY, h:mm:ss a")}</p>) },
        //sorter: (a, b) => a.CreationTime.length - b.CreationTime.length,
        //sorter: (a, b) => new Date(a.CreationTime) - new Date(b.CreationTime),
        //defaultSortOrder: 'descend',
        sorter: (a, b) =>
            // new Date(moment(a.CreationTime, "MM/DD/YYYY, h:mm:ss a").format("LLL")) -
            // new Date(moment(b.CreationTime, "MM/DD/YYYY, h:mm:ss a").format("LLL"))
            new Date(a.CreationTime) - new Date(b.CreationTime),

    },
    {
        title: 'Direct Storage URL',
        dataIndex: 'URL',
        key: 'URL',
        render: (text) => <a href={text}>Download</a>
    },
];

  const hasSelected = selectedRowKeys.length > 0;
  return (
    <div>
    
    <div className='dtext'>
     <br></br>
     <p style={{ color: 'red' }}> 
           NOTE - Please be aware that due to size of these files, attempting to download multiple files simultaneously through the browser may result in unsuccessful downloads. We strongly recommend limiting your simultaneous downloads to no more than 10 files at a time. This approach increases the likelihood of successful download of the files. 
     </p>    
       </div>
      <div
        style={{
          marginBottom: 16,
          display: 'flex',
        flexDirection: 'row',
        gap: '2vh',
        //justifyContent: 'space-around',
        //width: '100%',

        }}
      >
        <Button className='buttoncolor' type="primary" onClick={start} disabled={!hasSelected} loading={loading}>
          
        {/* onClick={() => {
                        handleReset(clearFilters)
                    }} */}
          Clear
        </Button>
        <div>
        <Button className='buttoncolor' type="primary" onClick={() => {downloadFile() }}> Download</Button>  &nbsp;
        {/* <Button onClick={ () => resetFilters() }>Reset Filters</Button> */}

      </div>
        <span
          style={{
            marginLeft: 8,
          }}
        >
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
      </div>
      <Table rowSelection={rowSelection} columns={columns}  rowKey={(record) => record.URL} dataSource={props.list} key={tableKey}/>
    </div>
  );
};
export default FileAPIData;