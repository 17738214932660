const environmentConfigs = {
    fhDev: {
      type: "dev",
      appName: "cffh",
      environmentDescription: "Development",
      getFileListUrl: "https://mrfstorageexplorer-dot-cost-transparency-dev.uk.r.appspot.com/StorageExplorer/geturl?bucketid=",
      //apiHostUrl: "http://localhost:3000",
      b2cAuthUrl: "https://ngactfhnonprod.b2clogin.com/ngactfhnonprod.onmicrosoft.com/B2C_1A_signup_signin",
      b2cPassworkResetUrl: "https://ngactfhnonprod.b2clogin.com/ngactfhnonprod.onmicrosoft.com/B2C_1A_PasswordReset",
      b2cApiScope: "0853e4a9-a10d-4f80-9d1c-b0a8e24a2e25",
      b2cLoginScope: "offline_access",
      b2cDomainName: "ngactfhnonprod.b2clogin.com",
      prefix: "fh_",
    },
    fhProd: {
      type: "prod",
      appName: "cffh",
      environmentDescription: "Production",
      getFileListUrl: "https://mrfstorageexplorer-dot-cost-transparency-prod.uk.r.appspot.com/StorageExplorer/geturl?bucketid=",
      //apiHostUrl: "https://mrf-react-ui-dot-cost-transparency-dev.uk.r.appspot.com/",
      b2cAuthUrl: "https://ngactfhprod.b2clogin.com/ngactfhprod.onmicrosoft.com/B2C_1A_signup_signin",
      b2cPassworkResetUrl: "https://ngactfhprod.b2clogin.com/ngactfhprod.onmicrosoft.com/B2C_1A_PasswordReset",
      b2cApiScope: "342173e4-0f04-4321-af27-a68ce942dee6",
      b2cLoginScope: "offline_access",
      b2cDomainName: "ngactfhprod.b2clogin.com",
      prefix: "fh_",
    },
    siDev: {
      type: "dev",
      appName: "cfsi",
      environmentDescription: "SI Development",
      apiHostUrl: "https://mrf-si-ui-dot-cost-transparency-dev.uk.r.appspot.com/",
      getFileListUrl: "https://mrfstorageexplorer-dot-cost-transparency-dev.uk.r.appspot.com/StorageExplorer/geturl?bucketid=",
      b2cAuthUrl: "https://ngamrfnonprod.b2clogin.com/ngamrfnonprod.onmicrosoft.com/B2C_1A_signup_signin",
      b2cPassworkResetUrl: "https://ngamrfnonprod.b2clogin.com/ngamrfnonprod.onmicrosoft.com/B2C_1A_PasswordReset",
      b2cApiScope: "d2762289-acd1-4dad-b1c7-daa626fdc127",
      b2cLoginScope: "offline_access",
      b2cDomainName: "ngamrfnonprod.b2clogin.com",
      prefix: "si_",
    },
    siProd: {
      type: "prod",
      appName: "cfsi",
      environmentDescription: "SI Production",
      apiHostUrl: "https://mrf-si-ui-dot-cost-transparency-prod.uk.r.appspot.com/",
      getFileListUrl: "https://mrfstorageexplorer-dot-cost-transparency-prod.uk.r.appspot.com/StorageExplorer/geturl?bucketid=",
      b2cAuthUrl: "https://ngamrfprod.b2clogin.com/ngamrfprod.onmicrosoft.com/B2C_1A_signup_signin",
      b2cPassworkResetUrl: "https://ngamrfprod.b2clogin.com/ngamrfprod.onmicrosoft.com/B2C_1A_PasswordReset",
      b2cApiScope: "cccb1f26-5d96-4127-b125-15b286a1cfb8",
      b2cLoginScope: "offline_access",
      b2cDomainName: "ngamrfprod.b2clogin.com",
      prefix: "si_",

    },
  };
  
  export const hostServer = () => {
    let envType;
    if (typeof window !== "undefined" && document) {
      envType = document.domain ? document.domain : "dev";
  
      if (envType.length > 0 && envType.includes("mrf-react-ui-dot-cost-transparency-dev.uk.r.appspot.com")||envType.includes("firsthealth-dev.aetna.com")) {
        envType = "fhDev";
      } else if (envType.length > 0 && envType.includes("mrf-si-ui-dot-cost-transparency-dev.uk.r.appspot.com")||envType.includes("mrf-dev.aetna.com")) {
        envType = "siDev";
      } else if (envType.length > 0 && envType.includes("mrf-react-ui-dot-cost-transparency-prod.uk.r.appspot.com")||envType.includes("firsthealth.aetna.com")) {
        envType = "fhProd";
      } else if (envType.length > 0 && envType.includes("mrf-si-ui-dot-cost-transparency-prod.uk.r.appspot.com")||envType.includes("mrf.aetna.com")) {
        envType = "siProd";
      } else if (envType.length > 0 && envType.includes("localhost")) {
        envType = "siDev";
        //envType = "fhDev";
      } 
    }
  
    return envType;
  };
  
  export const appEnvironment = hostServer();
  const envConfig = environmentConfigs[appEnvironment] || environmentConfigs["dev"];
  
  if (appEnvironment) {
    console.log(
      "app environment configured to: " + envConfig?.type + ", detected host env: " + appEnvironment
    );
    console.log("environment host: " + envConfig?.apiHostUrl);
    console.log(environmentConfigs);
    console.log(document.domain);
    console.log(window.location.origin);

    console.log(envConfig?.appName);
    console.log(envConfig.prefix);

  }
  
  export default envConfig;