import React, { Component } from 'react'
import { Navbar, Modal } from "react-bootstrap";
import "../styles/index.css"
import jwt_decode from "jwt-decode";
import validator from 'validator'
import { useEffect, useState } from "react";
//import Table from "../views/FileTable1";
import FileTable from './FileTable';
//import SIFileTable from './SIFileTable';
import axios from 'axios';
//import {DropDownMenu,MenuItem,MuiThemeProvider} from 'material-ui';
//import { Button } from "react-bootstrap";
//import "bootstrap/dist/css/bootstrap.min.css";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import envConfig from "../config";
import FileAPIData from './FileAPIData';


//import { CloseButton } from 'react-toastify/dist/components';

export default class LandingPage extends Component {

    constructor(props) {
        super(props);

        var token = localStorage.getItem("accessToken");
        if (token) {
            var decoded = jwt_decode(token);
            console.log(decoded);
            this.state = {
                selectValue: '',
                fileDetails: [],
                decoded: decoded,
                beId: decoded.extension_beId.split(','),
                prefix: envConfig.prefix,
                progress: false,
                open: false
            }
        } else {
            window.location.reload();
        }
        this.handleChange = this.handleChange.bind(this);
        this.fileSize = this.fileSize.bind(this);
        //const classes=useStyles();
        //toast.configure();
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    handleChange = (event) => {

        this.setState({ selectValue: event.target.value, fileDetails: [], open: true },
            () => {
                this.getListFiles(this.state.selectValue);

            }
        )

    }

    downloadStorageFile(row) {
        //console.log(this.state.selectValue)
        //console.log(row.FileName);
        const toast1 = toast.loading("Download is started",
            {
                type: toast.TYPE.INFO,
                closeButton: true
            });

        //this.setState({progress:true})

        console.log('https://cost-transparency-dev.uk.r.appspot.com/storageexplorer/downloadfile?bucketid=' + this.state.selectValue + '&filename=' + row.FileName)
        axios({
            url: 'https://cost-transparency-dev.uk.r.appspot.com/storageexplorer/downloadfile?bucketid=' + this.state.selectValue + '&filename=' + row.FileName,
            method: 'GET',
            responseType: 'blob',
            /*onDownloadProgress: progressEvent => {
              let { progress } = this.state;
              progress = (progressEvent.loaded / progressEvent.total) * 100;
              this.setState({ progress });
            }*/
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            setTimeout(() => {
                link.setAttribute('download', row.FileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            }, 3000)
            toast.update(toast1, {
                render: "Download is in progress - " + 'Filename   ' + row.FileName,
                type: toast.TYPE.SUCCESS,
                autoClose: 2000,
                closeButton: true,
                isLoading: false
            });
            this.setState({ progress: false })
        })
            .catch((error) => {
                console.log("error" + error)
                this.setState({ progress: false })
                if (error) {
                    toast.update(toast1, {
                        render: error.message + " - " + " Due to an error, please click the URL link to download the direct file.",
                        type: toast.TYPE.ERROR,
                        autoClose: 2000,
                        closeButton: true,
                        isLoading: false
                    });
                }
            })


    }

    componentDidMount() {
        const value = this.state.prefix + this.state.beId[0];
        this.getListFiles(value);

    }

    // To get the list of files detail from DotNet API
    getListFiles(input) {
        //axios.get(process.env.REACT_APP_GETFILELIST_API_URL+input)
        if (input){
            axios.get(envConfig.getFileListUrl + input)
            .then(response => {
                console.log(response.data);
                this.setState({
                    fileDetails: response.data,
                    open: false
                });
                //window.location.reload();
            })
            // .then((res) => {
            //   setTimeout(()=>{
            //     setIsLoading(false)
            //   }, 2000)
            // })

            .catch((error) => {
                console.log(error)
                //setIsLoading(false);
            })
            .finally(() => {
                this.setState({ open: false });
            })
        }else{
            alert("BEID is not present");
          }    
    }

    //To get the total file size
    fileSize() {
        let nf = new Intl.NumberFormat('en-US');
        if (this.state.fileDetails.length > 0) {
            var totalSize = 0;
            for (var i = 0; i < this.state.fileDetails.length; i++) {
                totalSize = totalSize + this.state.fileDetails[i].Size;
                //console.log("fileSize", totalSize/1024);
            }
            totalSize = totalSize.toFixed(6)
        } else {
            var totalSize = 0;
        }
        //return totalSize.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return nf.format(totalSize);

    }

    render() {
        //if (localStorage.getItem('token') === null) {
        //var token = localStorage.getItem("accessToken");
        // try {
        //   if (token === null) {
        //     let token_expired = token
        //     let decoded = decode(token_expired, { header: true })
        //     let decode_string = decoded['exp']
        //     var current_time = Date.now() / 1000
        //     if (decode_string < current_time) {
        //       localStorage.clear()
        //     }
        //   }
        // } catch (e) {
        //   localStorage.clear() //what you need to do incase the jwt is not valid
        //   console.log(e) //for your own debugging
        // }
        const beId = this.state.beId;
        const decoded = this.state.decoded;
        const prefix = this.state.prefix;
        const fileSize = this.fileSize();

        //var decoded = jwt_decode(token);
        //var beId = decoded.extension_beId.split(',');
        //var beId = decoded.extension_beId;
        //const prefix = "fh_";
        //var totalRecord = Table.Data.count;
        //console.log(totalRecord);
        return (
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={this.state.open}
                    onClick={this.handleClose.bind}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                {/* <Navbar bg="warning" variant="dark" style={{backgroundColor: "white"}}> */}
                <Navbar style={{ backgroundColor: "mediumorchid", height: "50px" }} variant="dark">
                    <h2>Proprietary Files</h2>
                </Navbar>
                <h6 className='name m-3'>Username: {decoded.email}</h6>
                <h6 className='name m-3'>Total number of files: {this.state.fileDetails.length}  &nbsp;&nbsp;&nbsp;&nbsp;    Total files size: {fileSize + "  MB"}</h6>
                {/* <h6 className='name m-3'>Total files size: {fileSize + "  MB"} </h6> */}

                <div className='name m-3' style={{ backgroundColor: "white", fontSize: '1em', fontWeight: '500' }}>
                    {/* <div className='container ml-3' style={{ border: "solid", backgroundColor: "white", fontSize: 'x-large', fontWeight: '600' }}> */}
                    <label className='bucketSelect'>
                        File Batch: &nbsp;
            </label>
                    {beId ?
                        <select className='bucketSelect' value={this.state.selectValue} onChange={this.handleChange} >bucketName
                {beId.map((item) =>
                            <option key={item}>{prefix + validator.trim(item)}</option>
                        )}
                        </select>
                        : <h6> Bucket name is not mapped to the user</h6>}
                    <div className="App">
                        {/* <h5>Provider & Rate Files</h5> */}
                        {/* {this.state.fileDetails.length !== 0 ? */}
                        {this.state.progress ? <div className='root'> </div> : null}
                        { /*<FileTable list={this.state.fileDetails} onclick={this.downloadStorageFile.bind(this)} /> */}
                         <FileAPIData list={this.state.fileDetails} onclick={this.downloadStorageFile.bind(this)} />

                        {envConfig.appName === 'cfsi' ?
                            <div className='dtext'>
                                <p>
                                    Consistent with the requirements of the federal Transparency in Coverage final rule, this machine-readable file is publicly available and accessible free of charge without any condition. This file displays negotiated rates for all covered items and services between Aetna and in-network providers for the plans indicated by <b>Health Insurance Company.</b> Some in-network provider arrangements do not prescribe rates for all covered items and services. In those cases, the provider’s reimbursement might be determined in several different ways, but this file would not contain that information.
                                </p>
                                <p>The MRF will contain in-network (INN) negotiated rate information except for providers with unique payment methodologies that may not be available.</p>
                                <p>This file is not designed as a consumer tool. Members are encouraged to use the more member-friendly price estimator tools available through <a href="https://www.aetna.com/">Aetna.com</a>. This file is not a guarantee of coverage, nor a guarantee of the reimbursement rate that will be applied for any procedure. The claims reimbursement process is subject to a variety of factors that are not addressed in this file, including eligibility, medical necessity determinations, utilization management requirements, exclusions, and other plan terms. This file also does not offer any information on a member’s out-of-pocket charges.
                                </p>
                                <p>The information in this file is accurate as of a date immediately prior to the “Last Updated” date noted at the top of this sheet and is subject to change without notice. This file contains information for participating (In-Network) or non-participating (Allowed Amount) providers over a broad geography. The members in the Plan indicated in the [Plan Name] may not access providers in this entire geographic area.
                                </p>
                                <p>
                                    Consistent with the requirements of the federal Transparency in Coverage final rule, this file is publicly available and accessible free of charge without any condition. This file displays both billed charges and allowed amounts for all covered items and services of the non-participating providers for the plans indicated by <b>Group Health Plan or the Health Insurance Issuer </b>over a prescribed period under the rule.
                                </p>
                                <p>The disclaimers are applicable to the following legal entities: Aetna Life Insurance Company, Aetna Health Inc., Aetna Health of California Inc., Aetna Health Insurance Company of New York, Aetna Health Insurance Company, Aetna HealthAssurance Pennsylvania Inc. and/or Aetna Life Insurance Company (Aetna). In Florida, by Aetna Health Inc. and/or Aetna Life Insurance Company. In Utah and Wyoming, by Aetna Health of Utah Inc. and Aetna Life Insurance Company. In Maryland, by Aetna Health Inc.; Texas Health + Aetna Health Plan Inc. & Texas Health + Aetna Health Insurance Company; Sutter Health and Aetna Administrative Services LLC; Innovation Health Insurance Company and Innovation Health Plan, Inc.; Allina Health and Aetna Insurance Company; and Banner Health and Aetna Health Insurance Company and Banner Health and Aetna Health Plan Inc.</p>
                            </div>
                            : ""}
                        {/* : <Backdrop className={classes.backdrop} open>

                <CircularProgress color="inherit" />
              </Backdrop>
              } */}
                        {/*} <Modal show={this.state.progress} dialogClassName='Modal' backdrop="static" keyboard={false}>
              <Modal.Header closeButton>
                  Download is in progress
              </Modal.Header>
              <Modal.Body>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              </Modal.Body>
            </Modal> */}

                    </div>


                    {/* hardcoded JSON
          <div>
            <h1>Provider & Rate Files</h1>
            <Table />
          </div> */}


                </div>
                <ToastContainer transition={Zoom} autoClose={2000} draggable={false} position={toast.POSITION.TOP_CENTER} bodyClassName="toastBody" />

            </>
        )
        //}
    }
}